import React from 'react'
import Header from '../../Components/Header';
import Address from '../../Components/Address';
import Footer from '../../Components/Footer';
import './style.css'
import { Link } from 'react-router-dom';

function PaymentStatus() {
    const status = true;
    return (
        <div>
            <Header />
            {status ? <div className='payment-status'>
                <div className="container">
                    <img src="Group 52.png" alt="" />
                    <div className='status'>payment successful</div>
                    <div className="btn btn-primary w-50 my-4">print receipt</div>
                </div>
            </div> :
                <div className='payment-status'>
                    <div className="container">
                        <img src="sad 1.png" alt="" />
                        <div className='status'>payment failed</div>
                        <div className="btn btn-primary w-50 my-4"><Link to={"/payment"} className='btn-link'>try again</Link></div>
                    </div>
                </div>}
            <Address />
            <Footer />
        </div>
    )
}

export default PaymentStatus
