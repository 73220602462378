import { useEffect, useState } from "react";
import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import "./style.css";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import CareerService from "../../services/CareerService";
import { JOBS } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import Loader from "../LoaderSpinner";
import parse from 'html-react-parser';

const Career = () => {
  const dispatch = useDispatch();
  let data = useSelector((state) => state?.Jobs);
  if (data === undefined) data = [];
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    CareerService.getJobs(pageNum)
      .then((res) => {
        dispatch({
          type: JOBS,
          payload: res,
        });
        console.log(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        navigate("/pagenotfound");
      });
  }, []);

  const jobData = data?.data || [];
  console.log(jobData)

  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const lastPage = 1;
  const pageTitle = "ALC Travels - Careers";

  const HandleNextPage = () => {
    setPageNum(pageNum + 1);
  }

  const HandlePrevPage = () => {
    setPageNum(pageNum - 1);
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SocialSideBar />
      <Header />
      <div className="page-banner">
        <img src="./career.svg" className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>Join ALC</h1>
        </div>
      </div>

      {loading ? <div><Loader /></div> : <><div className="section_career">
        <div className="container-fluid">
          {jobData?.map((elem) => {
            return (<div className="card" key={elem.id}>
              <div className="row align-items-center">
                <div className="col-12 col-md-12 col-lg-10">
                  <h2>{elem.title}</h2>
                  <p>
                    {elem.sub_title}
                  </p>
                </div>
                <div className="col-12 col-md-12 col-lg-2 text-center">
                  <a href="https://alcgroup.co.in/join-alc" target="_blank"><button>APPLY NOW</button></a>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  {parse(elem.description + "")}
                </div>
              </div>
            </div>)
          })}
        </div>
      </div>
        <div className="pagination_card">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item page-circle">
                <button className="btn btn-primary" type="button" disabled={pageNum <= 1} onClick={HandlePrevPage}>&larr;</button>
              </li>
              <li className="page-item page-circle">
                <a className="page-link" href="#">
                  {pageNum}
                </a>
              </li>
              <li className="page-item page-circle">
                <button className="btn btn-primary" type="button" disabled={pageNum >= lastPage || lastPage == undefined} onClick={HandleNextPage}>&rarr;</button>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div></>}

      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default Career;
