
const RouteURL = {
    // Home Page Service Route URL
    getHomePage:"home",
    getCruisePage:"cruise",
    getTourPage:"destinationPackage",
    postContact:"enquiry",
    getDetailedPackage:"tourDetail",
    getDetailedBlog:"blog-detail",
    getJobs:"jobs",
    getSearch:"search-destination"
}

export default RouteURL;