import Slider from "react-slick";
import "./style.css";
import { useSelector } from "react-redux";
const OurClient = (props) => {
  let data = useSelector((state) => state?.tableHeader);
  const ourClient = data?.data?.ourClient || [];
  const heading_alignment = props.alignment === undefined ? "" : props.alignment;
  var settings = {
    slidesToShow: 5,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay : true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.9,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2.3,
        },
      },
    ],
  };
  return (
    <section className="section_client">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="row">
            <div className="col-lg-12 heading">
              <h2 style={{fontWeight : "bold"}} className={heading_alignment}>Our Clients</h2>
            </div>
          </div>
          <Slider {...settings}>
            {ourClient.map((banner) => (
              <div className="col-lg-2 col-md-4 col-4 our-client" key={banner.id}>
                <div className="rounded-3 client-inner">
                  <img src={`https://admin.alctravels.com/storage/${banner.our_client_image}`} alt="" className="w-75" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default OurClient;
