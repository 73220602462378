
import API from './API';
import RouteURL from './RouteUrl';

const CruiseApiService = {

    getCruisePage: function (page) {
        return new Promise((resolve, reject) => {
            API.get(`${RouteURL.getCruisePage+"?page="+page}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

}

export default CruiseApiService;