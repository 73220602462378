import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import CreatePdf from '../../Components/CreatePdf'
import { useLocation } from 'react-router-dom';

const GeneratePdf = () => {
    const location = useLocation();
    const data = location?.state?.data || [];
    const incdata = location?.state?.incData || [];
    const excdata = location?.state?.excData || [];
    const hotelDetails = location?.state?.hotelDetails || [];
    const disclaimer = location?.state?.disclaimer || [];
    const overview = location?.state?.overview || [];
    const title = location?.state?.title || [];
    console.log(location.state);
    return (
        <div>
          <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <CreatePdf data={data} incdata={incdata} excdata={excdata} hotelDetails={hotelDetails} disclaimer={disclaimer} title={title} overview={overview}/>
          </PDFViewer>
        </div>
      );
}

export default GeneratePdf;