import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useEffect, useState } from "react";
import SearchService from "../../services/SearchService";
import { SEARCH } from "../../redux/types";
import { Link } from "react-router-dom";

const Search = () => {
  const dispatch = useDispatch();

  let data = useSelector((state) => state?.Search);
  if (data === undefined) data = [];
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState();
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    SearchService.getSearch(searchValue)
      .then((res) => {
        dispatch({
          type: SEARCH,
          payload: res,
        });
        console.log(res);
        setSearchData(res?.data)
        console.log(searchData)
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [searchValue, isInputFocused, dispatch]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(()=>{
      setIsInputFocused(false);
    },222)
  };

  return (
    <>
      <div class="search-container">
        <div className="search-inner">
          <img src="../../../search.svg" alt="Search" />
          <input
            type="text"
            placeholder="Search holidays by destination & theme"
            id="searchInput"
            onChange={handleSearchChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
        <div className="search-btn">
          <button type="submit " className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      {isInputFocused && (
        <div className="search-list search-container" style={{zIndex:"9999"}}>
          <ul style={{listStyle :"none"}}>
            {searchData?.map((elem, index) => (
              <Link
                key={index}
                to={'/package/' + elem?.destination_slug + "/" + elem?.destination_id}
                style={{textDecoration:"none" , color:"black"}}
              >
                <li>{elem?.destination_name}</li>
              </Link>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Search;
