import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import { Helmet } from "react-helmet";
import "./style.css";

const DestinationWedding = () => {
    const pageTitle = "ALC Travels - Destination Weddings";
    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <SocialSideBar />
            <Header />
            <div className="page-banner">
                <img src="./wedding.png" className="w-100" alt=""></img>
                <div className="pagebanner_heading">
                    <h1>Destination Wedding</h1>
                </div>
            </div>
            <div className="wedding-content mx-5 mt-5">
                <p>
                    Welcome to ALC where we are specialized in turning dreams into reality by crafting unforgettable destination weddings.
                </p>

                <p>
                    Our services includes Planning and Conceptualization, Exquisite Theme and Decor Selection, Engagement Ceremony, Pre-wedding Photo shoot, Wedding Venue Selection, Logistic Management, Stage and Mandap Set-up, Light and Sound, Artist Management & Entertainment (Dance troop, Celebrity management etc.) and Catering.
                </p>

                <p>
                    Immerse yourself in the magic of love as we curate exquisite celebrations in breath-taking locations around the world. Our Wedding experts ensure everything is seamlessly managed, from idyllic beach ceremonies to romantic mountain vows. Picture yourself against stunning backdrops, surrounded by the beauty of nature, or immersed in the cultural richness of an unusual setting.
                    At ALC, we transform your wedding day into a cherished adventure, creating timeless memories that last a lifetime. Let us handle the logistics while you focus on the joy of your special day.
                </p>

                <p>
                    Start your journey with us and make your destination weddings come true.
                </p>

            </div>
            <div className="mt-5">
                <Address />
            </div>
            <Footer />
        </div>
    );
};

export default DestinationWedding;
