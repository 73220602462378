
import API from './API';
import RouteURL from './RouteUrl';

const TourPageService = {
    getTourPage: function (id,page) {
        return new Promise((resolve, reject) => {
            API.get(`${RouteURL.getTourPage+"?id="+id+"&page="+page}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    }
}

export default TourPageService;