import "./style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const SocialSideBar = () => {
    return (
        <div className="main">
            <div className="side_social_icon d-flex justify-content-evenly flex-column">
                <div>
                    <a href="https://www.facebook.com/alctravelsonline/" target="_blank" className="d-flex flex-column"><i class="bi bi-facebook"></i></a>
                </div>
                <div>
                    <a href="https://www.instagram.com/alctravelsonline/" target="_blank" className="d-flex flex-column"><i class="bi bi-instagram"></i></a>
                </div>
                <div>
                    <a href="https://www.linkedin.com/company/alctravelsonline" target="_blank" className="d-flex flex-column"><i class="bi bi-linkedin"></i></a>
                </div>
                <div>
                    <a href="#" target="_blank" className="d-flex flex-column"><i class="bi bi-youtube"></i></a>
                </div>
                <div>
                    <a href="https://wa.me/919555423232" target="_blank" className="d-flex flex-column"><i class="bi bi-whatsapp"></i></a>
                </div>
            </div>
        </div>
    );
};
export default SocialSideBar;
