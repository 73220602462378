
import API from './API';
import RouteURL from './RouteUrl';

const HomePageService = {

    getHomePage: function () {
        return new Promise((resolve, reject) => {
            API.get(`${RouteURL.getHomePage}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

}

export default HomePageService;