
import API from './API';
import RouteURL from './RouteUrl';

const DetailedPackage = {
    getDetailedPackage: function (id) {
        return new Promise((resolve, reject) => {
            API.get(`${RouteURL.getDetailedPackage+"?id="+id}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    }
}

export default DetailedPackage;