import API from './API';
import RouteURL from './RouteUrl';

const BlogDetailService = {
    getDetailedBlog: function (id) {
        return new Promise((resolve, reject) => {
            API.get(`${RouteURL.getDetailedBlog+"/"+id}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    }
}

export default BlogDetailService;