import React from "react";
import "./style.css";


const HotelCard = ({ destinationName, imageUrl,price }) => {
  return (
    <div className="mb-4 hotelcard">
      <div className="card">
      <img src={`https://admin.alctravels.com/storage/${imageUrl}`} alt={destinationName} />
        <div className="card-body">
          <div>
            <h5 className="card-title">{destinationName}</h5>
          </div>
          <div>
            <p>
              Starting From
              <br />{"₹"+price}/-
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
