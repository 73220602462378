import { Helmet } from "react-helmet";
import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState, useEffect } from "react";
import axios from 'axios';
import "./style.css";
import Loader from "../LoaderSpinner";
// import Modal from "@mui/material/Modal";

const Payment = () => {
    const pageTitle = "ALC Travels - India's Leading Travel Management Company";
    const [contactValue, setContactValue] = useState();
    const [Name, setName] = useState();
    const [Amount, setAmount] = useState();
    const [Email, setEmail] = useState();
    const [Invoice, setInvoice] = useState();
    const [Remark, setRemark] = useState();
    const [self, setSelf] = useState();
    const [oncheckOpen, setOnCheckOpen] = useState(false);
    const formId = "form";


    const data = {
        amount: Amount,  //Float //String
        firstname: Name,   //String
        email: Email,
        phone: contactValue
    };



    const makePayment = async () => {
        await paymentReq();
        // await responseReq();
    };


    const handleCheckClose = () => setOnCheckOpen(false); //close the modal
    const handleCheckOpen = () => setOnCheckOpen(true);

    const paymentReq = async () => {
        try {
            setOnCheckOpen(true);
            var res = await axios.post(`https://admin.alctravels.com/api/generateHash`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setOnCheckOpen(false);
        } catch {
            console.log("Payment Error");
        }
    };

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Header />

            <div className="Payheading w-100 bg-primary d-flex">
                <h1 className="mx-auto my-auto text-white">Pay Online</h1>
            </div>

            {oncheckOpen ? <Loader /> : <div className="container my-5">
                <form>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Your Name
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                value={Name}
                                onChange={setName}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Amount
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="number"
                                className="form-control"
                                id=""
                                min="0"
                                value={Amount}
                                onChange={setAmount}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Email address
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={Email}
                                onChange={setEmail}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Phone Number
                        </label>
                        <div className="col-sm-10">
                            <div className="phone-input">
                                <PhoneInput
                                    autocompleteSearch={true}
                                    country={'in'}
                                    value={contactValue}
                                    onChange={setContactValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Invoice No.
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                value={Invoice}
                                onChange={setInvoice}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-sm-2 col-form-label">
                            Remark
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                value={Remark}
                                onChange={setRemark}
                            />
                        </div>
                    </div>

                    {/* <Modal
                        open={oncheckOpen}
                        onClose={handleCheckClose}
                        closeAfterTransition
                        style={{
                            background: "white",
                            width: "50%",
                            margin: "auto",
                            height: "20vh",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "white",
                                height: "20vh",
                                padding: "8%",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <a
                                href={self}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <div className="mb-3 row">
                                    <div className="col-sm-10 offset-sm-2">
                                        <button type="submit" className="btn btn-primary w-100">
                                            Pay Now
                                        </button>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Modal> */}

                </form>
            </div>}



            <div className="mt-5">
                <Address />
            </div>
            <Footer />
        </>
    );
};

export default Payment;
