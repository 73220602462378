import React from 'react'
import Header from '../../Components/Header'
import Address from '../../Components/Address'
import Footer from '../../Components/Footer'
import { Helmet } from "react-helmet";
import './index.css'

function TermsAndConditions() {
    const pageTitle = "ALC Travels - Terms and Conditions";
    window.scroll(0, 0);
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Header />
            <div className="terms-and-conditions">
                <div className="heading">
                    <h2>terms & conditions</h2>
                </div>
                <div className="data w-100">
                    <p><h3>USER'S RESPONSIBILITY OF COGNIZANCE OF THIS AGREEMENT</h3>
                    </p>
                    <p>This website is published and maintained by ALC, a company incorporated and existing in
                        accordance with the laws of the Republic of India. This page sets forth the terms and conditions
                        under which ALC provides the information on this Website, as well as the terms and conditions
                        governing your use of this site. The Users availing services from ALC shall be a natural or legal
                        person and deemed to have read, understood and expressly accepted the terms and conditions of
                        the agreement, which alone shall govern the desired transaction or provision of such service by
                        ALC for all purposes, and shall be binding on the user. All rights and liabilities of the User with
                        respect to any services to be provided by ALC shall be restricted to the scope of this agreement. If
                        you do not accept these terms and conditions, do not continue to use or access this site, or order
                        any searches.
                    </p><br />
                    <p>In addition to this agreement, there are certain terms of service (TOS) specific to the services
                        rendered/products provided by ALC like the air tickets, MICE, bus, rail, holiday packages, etc. Such
                        TOS will be provided / updated by ALC which shall be deemed to be a part of this Agreement and
                        in the event of conflict between such TOS and this agreement, the terms of this agreement shall
                        prevail. The user shall be required to read and accept the relevant TOS for the service/ product
                        availed by the user.
                    </p><br />
                    <p>Additionally, the Service Provider itself may provide terms and guidelines that govern particular
                        features, offers or the operating rules and policies applicable to each Service (for example, flights,
                        hotel reservation, Packages, etc). The User shall be responsible for ensuring compliance with the
                        terms and guidelines or operating rules and policies of the services provided with whom User
                        elects to deal, including terms and conditions set forth in a Service Providers’ fare rules, contract
                        of carriage or other rules.
                    </p><br />
                    <p>ALC’s Services are offered to the User Conditional on acceptance without modification of all the
                        terms, conditions and notices contained in the agreement and the TOS, as may be applicable from
                        time to time. For the removal of doubts, it is clarified that availing of the services by the User
                        constitutes an acknowledgement and acceptance by the User of the Agreement and the TOS. If
                        the User does not agree with any part of such terms, conditions and notices, the User must not
                        avail ALC’s Services.
                    </p><br />
                    <p>In the event that any of the terms, conditions and notices contained herein conflict with the
                        Additional Terms and guidelines contained within any other ALC Document, then these terms shall
                        be applicable.
                    </p><br />
                    <p><h3>SITE AND ITS CONTENTS</h3></p>
                    <p>
                        This Site is only for your personal use. You shall not distribute, exchange, modify, sell or transmit
                        anything you copy from this Site, including but not limited to any text, images, audio and video, for
                        any business, commercial or public purpose.
                    </p><br />
                    <p>
                        As long as you comply with the terms of these Terms and Conditions of Use, ALC grants you a
                        non–exclusive, non–transferable, limited right to enter, view and use this Site. You agree not to
                        interrupt or aempt to interrupt the operation of this Site in any way.
                    </p><br />
                    <p>You may only use this site to make legitimate reservations or purchases and shall not use this site
                        for any other purposes, including without limitation, to make any speculative, false or fraudulent
                        reservation or any reservation in anticipation of demand. You shall not make reservation in the
                        name of fictitious person(s). You agree that you will not use any device, so[ware or routine that
                        would interfere or be reasonably expected to interfere with the normal working of this site. You
                        agree that you shall not take any action that imposes a burden or load on our infrastructure that
                        ALC deems in its sole discretion to be unreasonable or disproportionate to the benefits ALC
                        obtains from your use of the site. You are prohibited from posting or transmiমng any unlawful,
                        threatening, libelous, defamatory, obscene, indecent, inflammatory, pornographic or profane
                        material or any material that could constitute or encourage acts or omissions that would amount
                        to a criminal offense or aempt to commit a criminal offense, or give rise to civil liability, or
                        otherwise violate any law. In addition, you are prohibited from posting or transmiমng any
                        information which (a) infringes the rights of others or violates their privacy or publicity rights, (b) is
                        protected by patent, copyright, trademark or other proprietary right, unless with the express
                        wrien permission of the owner of such right, (c) contains a virus, bug, worm or other harmful item
                        or program that may damage, detrimentally interfere with, surreptitiously intercept and/or
                        expropriate any system, data or important information, (d) create any liability for us or cause us to
                        lose (fully or partially) the services of our ISPs or other suppliers, or (e) is used to unlawfully collude
                        against another person in restraint of trade or competition. You shall be solely liable for any
                        damages resulting from any infringement of copyright, trademark, or other proprietary right, or
                        any other harm resulting from your use of this site.
                    </p><br />
                    <p><h3>COPYRIGHT POLICY</h3></p>
                    <p>ALC holds the copyright in respect of this site, and the content provided in this site, including the
                        text, graphics, buon icons, audio and video clips, digital downloads, data compilations and
                        so[ware, may not be copied, reproduced, republished, uploaded, posted, transmied or
                        distributed without the wrien permission of ALC, and/or its third party providers and
                        distributors, except that you may download, display and print the materials presented on this site
                        for your personal, non-commercial use only. You may not use any "robot," "spider" or other
                        automatic device, or a program, algorithm or methodology having similar processes or
                        functionality, or any manual process, to monitor or copy any of the Web pages, data or content
                        found on this site, in any case without the prior wrien permission of ALC. You agree that you will
                        not transmit or otherwise transfer any Web pages, data or content found on this site to any other
                        computer, server, Web site, or other medium for mass distribution or for use in any commercial
                        enterprise. Unauthorized use of this site and/or the materials contained on this site may violate
                        applicable copyright, trademark or other intellectual property laws or other laws. You must retain
                        all copyright and trademark notices, including any other proprietary notices, contained in the
                        materials, and you must not alter, obscure or obliterate any of such notices. The use of such
                        materials on any other Web site or in any environment of networked computers is prohibited. </p><br />
                    <p><h3>OWNERSHIP</h3></p>
                    <p>All materials on this Site, including but not limited to audio, images, so[ware, text, icons and such
                        like (the “Content”), are protected by copyright under international conventions and copyright
                        laws. You cannot use the Content, except as specified herein. You agree to follow all instructions
                        on this Site limiting the way you may use the Content.
                    </p><br />
                    <p>There are a number of proprietary logos, service marks and trademarks found on this Site whether
                        owned/used by ALC or otherwise. By displaying them on this Site, ALC is not granting you any
                        license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of
                        the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil
                        and criminal statutes.</p><br />
                    <p>You may download such copy/copies of the Content to be used only by you, to help you make the
                        purchase decision on products and services offered by ALC. If you download any Content from
                        this Site, you shall not remove any copyright or trademark notices or other notices that go with it.</p><br />
                    <p><h3>ALC RIGHTS</h3></p>
                    <p>If you send any communications or materials to the Site by electronic mail or otherwise, including
                        any comments, data, questions, suggestions or the like, all such communications are, and will be
                        treated by ALC, as non–confidential.
                    </p><br />
                    <p><h3>USER AGREEMENT
                    </h3></p>
                    <p>You hereby give up any and all claim that any use of such material violates any of your rights
                        including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to
                        credit for material or ideas, or any other right, including the right to approve the way ALC uses
                        such material.</p><br />
                    <p>Any material submied to this Site may be adapted, broadcast, changed, copied, disclosed,
                        licensed, performed, posted, published, sold, transmied or used by ALC anywhere in the world, in
                        any medium, forever.
                    </p><br />
                    <p><h3>TRANSMITTED MATERIAL</h3></p>
                    <p>Internet transmissions are never completely private or secure. You understand that any message or
                        information you send to this Site may be read or intercepted by others unless there is a special
                        notice that a particular message (for example, credit card information) is encrypted (send in code).
                        Sending a message to ALC does not cause ALC to have any special responsibility to you.
                    </p><br />
                    <p>The copyright in the contents of this website belong to ALC. Accordingly ALC reserves all rights.
                        Copying of part or all the contents of this website without permission of ALC is prohibited except
                        to the extent that such copying/printing is necessary for the purposes of availing of the paid
                        services provided.
                    </p><br />
                    <p><h3>PROMOTIONS / OFFERS / SCHEMES / CONTESTS</h3></p>
                    <p>Please note that in case of special offers, promotions or schemes additional terms and conditions
                        of these offers, promotions, schemes or contests will be applicable in addition to these terms and
                        conditions. You might have to adhere to the payment schedule prescribed under such offers,
                        promotions, schemes or contests in order to be eligible to avail benefits under such offers,
                        promotions, schemes or contests. If you fail to make the payment by the due date and/or do not
                        comply with all the terms and conditions of the offer, promotion, scheme or contest you would not
                        be entitled to receive the benefit under such offer, promotion, scheme or contest. The Promotions
                        / Offers / Schemes / Contests can also be discounted at any time without giving any specific
                        notice</p><br />
                    <p><h3>BASIC TRAVEL QUOTA</h3></p>
                    <p>As per the current guidelines of RBI, all residents holding Indian Passport are entitled to avail an
                        amount not exceeding US$ 2, 50, 000 or its equivalent in one financial year for one or more
                        private/leisure visits under the Basic Travel Quota (BTQ). However, the traveller cannot avail in
                        excess of US $ 3,000 per visit or its equivalent in cash, except as specified in the exceptions
                        provided by the RBI guidelines. It is mandatory for you to avail the foreign exchange component of
                        the tour cost from under your BTQ entitlement from an authorized dealer licensed to provide such
                        services as per the guidelines issued by RBI from time to time. You shall pay the foreign exchange
                        component of the total tour cost at the prevailing rate to the dealer along with signed BTQ form &
                        A2 form. You can draw foreign exchange for your personal use on tour from the same authorized
                        dealer from your balance BTQ entitlement. This is as per GOI rules & regulations. You may carry
                        this partly in currency, partly in traveller cheques (TC) which are safer and easily encashed for a
                        small service fee. They can also be replaced if they are stolen or lost, if you record TC numbers and
                        retain counterfoil subject to the rules applicable.</p><br />
                    <p><h3>PAYMENT TERMS FOR FOREX COMPONENT</h3></p>
                    <p>
                        <ol>
                            <li>The Forex(‘FX’) payment is a part of BTQ of the passenger</li>
                            <li> The immediate family/kin, e.g. father can pay for the children; mother can pay for the children
                                stands good. However, the person paying/cardholder should also be among the travellers.</li>
                            <li> If husband, wife and two children are travelling together as a family, husband’s card is acceptable.</li>
                            <li> The cardholder cannot use his/her card for payment of another person’s tour package.</li>
                            <li> The card that is swiped for payment of the tour’s foreign exchange should be of the cardholder himself/herself.
                            </li>
                            <li><b> For packages with FX (Foreign Exchange) component only, initial deposit amount collected in INR for booking, shall be adjusted towards the balance FX component at the time of final FX payment.
                            </b></li>
                            <li><b>The ROE (Rate of Exchange) for the entire FX component of the tour shall be considered as per the prevailing ROE on the day final payment is being made.</b></li>
                        </ol>
                    </p>
                </div>
            </div >
            <Address />
            <Footer />
        </>
    )
}

export default TermsAndConditions
