import React, { useEffect, useState } from "react";
import HomePageService from "../../services/HomePageService";
import "./style.css";
import { TABLEHEADER } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Search from "../Search";
import StickySearch from "../StickySearch";

const Header = () => {
  const dispatch = useDispatch();
  const [isSticky, setIsSticky] = useState(false);

  let data = useSelector((state) => state?.tableHeader);
  if (data === undefined) data = [];

  useEffect(() => {
    HomePageService.getHomePage()
      .then((res) => {
        dispatch({
          type: TABLEHEADER,
          payload: res,
        });
        console.log(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    setIsSticky(window.pageYOffset >= 630 && window.innerWidth > 1300);

    var stickyElement = document.getElementsByClassName("sticky-top")[0];
    if (stickyElement) {
      if (document.body.scrollTop > 630 || document.documentElement.scrollTop > 630) {
        if (isSticky)
          stickyElement.style.top = "0";
      } else {
        if (isSticky)
          stickyElement.style.top = "-100";
      }
    }
  }

  const handleRotate = (imageId) => {
    document.getElementById(imageId).classList.toggle("rotate-180");
  }

  return (
    <section className={isSticky ? "section_header sticky-top" : "section_header"} style={{ position: isSticky ? "fixed" : "", top: isSticky ? "-100px" : "", width: isSticky ? "100%" : "", transition: isSticky ? "top 0.3s" : "", boxShadow: isSticky ? " 0px 3px 4px 0px #00000033" : "" }}>
      <div className="container-fluid">
        <div className="row main-header">
          <div className="col-4 col-sm-3 col-lg-3 header-logo">
            <Link to="/"><img src="../../../alc-logo.svg" alt="logo" className="w-100" /></Link>
          </div>
          <div className="col-12 col-lg-6 header-menu" style={{ display: isSticky ? "none" : "" }}>
            <p>
              <Link to="/">HOME</Link> | <Link to="/about-us">ABOUT US</Link> |{" "}
              <Link to="https://alcgroup.co.in/" target="_blank">ALC GROUP</Link> | <Link to="https://alcfoundation.org/" target="_blank">CSR</Link> |{" "}
              <Link to="/contact-us">CONTACT US</Link>
            </p>
          </div>
          <div className="col-12 col-lg-6 mt-5" style={{ display: !isSticky ? "none" : "" }}>
            <p>
              {!isSticky ? <Search /> : <StickySearch />}
            </p>
          </div>
          <div className="col-6 col-sm-7 col-md-8 col-lg-3 header-btn">
            <div className="header-call">
              <Link to={window.innerWidth > 900 ? "/contact-us" : "tel:1800-309-9975"}>
                <img src="../../../phone-call.svg" alt="" />
              </Link>
            </div>
            <div>
              <Link to="https://payment.alctravels.com/" target="_blank">
                <button className="btn btn-primary" style={{ boxShadow: "0px 3px 4px 0px #00000033" }}>pay online</button>
              </Link>
            </div>
          </div>
          <div className="col-2 col-sm-2 col-md-1 mobile-toggle">
            <button
              className="btn btn-primary navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <span><img src="../../../mobile-icon.png" /></span>
            </button>
          </div>
          <div className="collapse" id="navbarToggleExternalContent">
            <div>
              <div className="mobile-menuinner">
                <ul>
                  <li><Link to="/">HOME</Link></li>
                  <li><Link to="/about-us">ABOUT US</Link></li>
                  <li><Link to="https://alcgroup.co.in/" target="_blank">ALC GROUP</Link></li>
                  <li><Link to="https://alcfoundation.org/" target="_blank">CSR</Link></li>
                  <li><Link to="/contact-us">CONTACT US</Link></li>
                  {data && data?.data?.navigation?.map((item, index) => {
                    return (
                      <li className="nav-item dropdown d-flex justify-content-between flex-wrap" id={"subCategory" + item.id} key={index}>
                        {item.url === null ? <>
                          <a aria-disabled={true} className="col-2 col-sm-2 col-md-1">
                            {item.tour_category_name}
                          </a>
                          <a
                            className={`btn navbar-toggler`} type="button" data-bs-toggle="collapse" data-bs-target={"#subCategory" + item.id} aria-controls={"subCategory" + item.id} aria-expanded="false" data-parent={"#subCategory" + item.id} href={"#subCategory" + item.id} onClick={() => { handleRotate("arrowImage" + item.id) }}>
                            <span><img src="../../../blue-arrow.png" id={"arrowImage" + item.id} /></span>
                          </a>
                        </> : <Link to={item.url} target="_blank" className="dropdown-mega nav-link active">
                          {item.tour_category_name}
                        </Link>
                        }
                        {item.ismegamenu == 1 ?
                          <li id={"subCategory" + item.id} className="panel-collapse collapse in mt-3 mx-3" style={{ flexBasis: "100%" }}>
                            {item.tour_subcategory.map((subCategory, subIndex) => (
                              <ul key={subIndex}>
                                <li>
                                  <Link to="" className="">
                                    {subCategory.tour_subcategory_name}
                                  </Link>
                                </li>
                                <li>

                                  {subCategory.tours.map((tours, subIndex) => (
                                    <ul key={subIndex}>
                                      <li>
                                        <Link to={"/package/" + tours?.destinations[0]?.destination_slug + "/" + tours.tours_id + "/" + tours.tours_url} className="dropdown-item">
                                          {" - "}{tours.tours_name}
                                        </Link>
                                      </li>
                                    </ul>
                                  ))}

                                </li>
                              </ul>
                            ))}
                          </li> : null}

                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {isSticky ? "" : <hr />}
        <nav className="navbar navbar-expand-lg megamenu" id="nonStickyNav" style={{ display: isSticky ? "none" : "" }}>
          <div
            className="collapse navbar-collapse megamenu_inner"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {data && data.data.navigation?.map((item, index) => {
                return (
                  <li className="nav-item dropdown" key={index}>
                    {item.url == null ? <Link to="" className={item.ismegamenu === 1 ? " dropdown-mega nav-link active" : " dropdown-mega nav-link active"}>
                      {item.tour_category_name}
                    </Link> : <a href={item.url} target="_blank" className="nav-link active"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                    >
                      {item.tour_category_name}
                    </a>}
                    {item.ismegamenu == 1 ? <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {item.tour_subcategory.map((subCategory, subIndex) => (
                        <ul key={subIndex}>
                          <li>
                            <Link to="" className="dropdown-item" style={{ fontWeight: "bold" }}>
                              {subCategory.tour_subcategory_name}
                            </Link>
                          </li>
                          <li>

                            {subCategory.tours.map((tours, subIndex) => (
                              <ul key={subIndex}>
                                <li>
                                  <Link to={"/package/" + tours?.destinations[0]?.destination_slug + "/" + tours.tours_id + "/" + tours.tours_url} className="dropdown-item">
                                    {tours.tours_name}
                                  </Link>
                                </li>

                              </ul>
                            ))}

                          </li>
                        </ul>
                      ))}
                    </div> : null}

                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
