import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from "react-redux";
import Contact from "../../../services/Contact";
import { CONTACTFORM } from "../../../redux/types";
import './style.css'

const InboxForm = () => {
  const [contactValue, setcontactValue] = useState();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    const full_name = document.getElementById('full_name').value;
    const mob_number = contactValue;
    const email = document.getElementById('email').value;
    const description = "NULL";
    Contact.newContact(full_name, mob_number, email, description)
      .then((res) => {
        dispatch({
          type: CONTACTFORM,
          payload: res,
        });
        console.log(res);
        alert(res.message)
      })
      .catch((error) => {
        console.log("error", error);
      });
    e.preventDefault();
  }

  return (
    <div className="inboxform">
      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
          <div className="col-md-6">
            <input type="text" name="name" placeholder="Full Name" id='full_name' />
          </div>
          <div className="col-md-6">
            <input type="email" name="email" placeholder="Email" id='email' />
          </div>
          <div className="col-md-6">
            <div className="phone-input-footer">
              <PhoneInput
                autocompleteSearch={true}
                country={'in'}
                value={contactValue}
                onChange={setcontactValue}
                inputStyle={{ background: '#545454', borderRadius: "10px !important", height: "45px !important" }}
                dropdownStyle={{ height: "80px" }}
                buttonStyle={{ background: '#545454' }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <button type="submit" className="w-100">SUBSCRIBE</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InboxForm;
