import React from 'react';
import "./style.css";

const Loader = () => {
  return (
    <div className="loaderBackground">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;