import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Banner from "../../Components/Home/Banner";
import Connects from "../../Components/Home/Connects";
import Destination from "../../Components/Home/Destinations";
import Features from "../../Components/Home/Feature";
import Highlights from "../../Components/Home/Highlights";
import LatestBlog from "../../Components/Home/LatestBlog";
import OurAcclamations from "../../Components/Home/OurAcclamations";
import OurClient from "../../Components/Home/OurClient";
import Search from "../../Components/Search";
import "./style.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loader from '../LoaderSpinner';
import { Helmet } from "react-helmet";
import SocialSideBar from "../../Components/SocialSideBar";
import parse from "html-react-parser";

const Home = () => {
  localStorage.clear();
  const clearCacheData = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };
  clearCacheData();
  const [expanded, setExpanded] = useState(false);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  const originalInternationalText = `<p>Best International Destinations: Planning an international holiday is a dream of many as it not 
only offers you the prospect of exploring diverse parts of the world but also an opportunity to 
experience a new perspective on life. We at ALC, offer you a wide array of all-inclusive travel 
deals on international tour packages which gives you the chance to explore the splendor of 
unique places. </p>
<p>Our destination experts can plan the international package by keeping your exact needs in 
mind. Whether you want to spend a few days amidst gigantic mountains or sunbathe along the 
white sandy beach, ALC Travels is here to provide you with the best of all, being the top-notch 
destination services provider yet at an unbelievable price. 
We curate special travel packages for every type of traveler. Some of the popular types that we 
offer are: </p>
<ul style={{text-align:"justify"}}><li>Lifetime experiences like exploring the Icy Paradise of Antarctica, Floating In the Dead 
Sea, Sailing in the New Zealand Fjords, Exploring the Hidden Machu Picchu, Visiting the 
Lake Louise in Canada by Rocky Mountain train, Witnessing the Northern Lights in 
Lapland with Icebreaker cruise, soaking in Blue Lagoon of Iceland or Admiring the 
Stunning Iguazu or Niagara Falls or staying in Underwater Hotel of Maldives, 
experiencing the best wildlife safari in Masai Mara, Kruger or in Serengeti to Bungee 
jumping in Queenstown, enjoying at the private yacht in the great barrier reef, riding the 
famous Gondola Ride in Venice, and hopping on the Hot air balloon in Cappadocia. </li>
<li>Those who want to enjoy a happening nightlife are recommended for destinations like
Buenos Aires, Las Vegas, Ibiza, Amsterdam, Phuket and Montreal. </li>
<li>Honeymooners can make their trip extremely special and unforgettable by visiting the 
most breathtaking destinations like Santorini, Tahiti, Bora Bora, Maui, Hawaii, Dubrovnik, 
Split, Zadar, Nice, Loire Valley, Paris, Capri, Sorrento, Venice, Phang Nga, and 
Maldives. </li>
<li>Those who love to admire the beauty of nature can choose the most scenic destinations 
like Queenstown, Seychelles, Fiji, Cape Town, Prague, Budapest, Mallorca and 
Barcelona. </li>
<li>For all the shopaholics, we can design an exclusive range of packages for cities like 
New York, Milan, Paris, Dubai, Singapore, Tokyo and Bangkok. </li></ul>
 
Get in touch with ALC to book your next overseas holiday package today!
`;

  const originalIndianText = `<p>Are you looking for an ultimate travel experience in India? If you are an ardent traveler and love 
to explore diverse destinations in India, then ALC Travels is here to curate the best holiday 
packages in India just for you. </p>
<p>Whether you want to explore the beautiful ranges of mountains or rest at the shore of a beach 
watching sunsets, we have covered all. Our exclusive packages are tailored perfectly to meet all 
your needs and desires. Our agents offer customized holiday tours in India for our clients to 
satisfy them to the core with our exclusive travel services. </p>
<p>We have a wide range of options right from explorin the bustling cities, and indulging into 
adventure activities to relaxing on the serene beaches and everything in between. Travelers can 
book exclusive Indian getaway offers for destinations as per their dream such as: </p>
<ul style={{text-align:justify}}><li> For scenic mountains, travelers can opt for popular destinations like Himachal 
Pradesh, Kashmir, Ladakh, Uttarakhand, North East, Ooty, Kodaikanal, Lonavala etc. </li>
<li>For beautiful beaches, we suggest choosing from destinations like Goa, Andaman, 
Lakshadweep, Karnataka and Kerala. </li>
<li> For world heritage and pilgrimage sites, travelers can choose Uttar Pradesh, Madhya 
Pradesh, Maharashtra, Tamil Nadu, Karnataka, Rajasthan and Gujarat. </li></ul>
<p>We have a team of experts, who curate each package carefully for you keeping in mind your 
safety, comfort and budget. ALC is known for the most budget-friendly Indian travel deals. We 
offer all-inclusive packages that take care of every aspect of your trip including transportation, 
accommodation, meals, guided tours, and activities. We handle all the minute details of your trip 
so that you can focus on making wonderful memories. </p>
<p>Get in touch with ALC Travels for all types of luxury, budget, exotic, and thrilling domestic 
packages. Unravel some of the exotic holiday destinations in India with us at an unbelievable 
price and thank us later! </p>
Book now and embark on the adventure of a lifetime`;

const originalBestDealsText = `<p>Are you looking for the best travel deal for your upcoming expedition? Look no more as ALC 
travels brings forth a wide range of travel packages specifically curated as per your needs. 
Being a top-notch travel company in India, we are involved in offering the best travel packages 
for your dream destination. </p>
<p>Whether you are looking for a luxurious getaway or a budget-friendly holiday, ALC Travels got 
you covered. Our professional travel agents carefully prepare all-inclusive vacation packages 
in India for the most popular destinations. We know the exact needs of our travel enthusiasts 
and curate something for every type. Our experts prepare a personalized itinerary that ensures 
an unforgettable journey of a lifetime. </p>
<h3>Top Deals for Travelers </h3>
<p>At ALC Travels, we curate the best deals for the top-rated holiday destinations in India. Our 
travel experts keep in mind the needs of all types of travel enthusiasts and prepare incredible 
holiday offers for everyone. </p>
<p>As holidays are evolving day by day, so are we. Being a top-notch tour and travel agency in 
India, we are offering one of the best and amazing deals on both domestic and international 
packages. Whether you are looking for a luxurious getaway or a budget-friendly excursion, we 
offer you all. </p>
<p>Each deal that we offer is meant to provide an experience of a lifetime to the travelers. Pick any 
destination, get your itinerary customized, and grab the best deal. We are offering: </p>
<ul style={{text-align:justify}}>
<li> Spiritual tours </li>
<li> Adventure tours </li>
<li> Cultural tours </li>
<li> Historical tours </li>
<li> Sightseeing tours </li>
<li> Specialty tours, etc. </li>
Get in touch with us to bag the best deal of the season only from ALC! Happy holidaying!`

  const pageTitle = "ALC Travels - India's Leading Travel Management Company";
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const readinginternationalText = !expanded ? originalInternationalText.slice(3, 200) + "...." : originalInternationalText;
  const readingindianText = !expanded ? originalIndianText.slice(3, 200) + "...." : originalIndianText;
  const readingbestdealsText = !expanded ? originalBestDealsText.slice(3, 200) + "...." : originalBestDealsText;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div style={{ display: loading === true ? "block" : "none" }}>
        <Loader />
      </div>
      <div style={{ display: loading === true ? "none" : "block" }}>
        <SocialSideBar />
        <Header />
        <Banner />
        <Search />
        <Connects />
        <section className="section_heading">
          <div className="container-fluid">
            <div className="mobile-font">

              <h4>Best International Destinations</h4>
            </div>
            <p style={{ fontSize: '17px' }}>
              {parse(readinginternationalText + "")}{' '}
              <Link to="#" onClick={toggleText}>
                {expanded ? 'Read Less...' : 'Read More…'}
              </Link>
            </p>
          </div>
        </section>
        <Destination type={"international"} />
        <Highlights />
        <section className="section_heading">
          <div className="container-fluid">
            <div className="mobile-font">
              <h4>Best Indian Destinations</h4>
            </div>
            <p style={{ fontSize: '17px' }}>
            {parse(readingindianText + "")}{' '}
              <Link to="#" onClick={toggleText}>
                {expanded ? 'Read Less...' : 'Read More…'}
              </Link>
            </p>
          </div>
        </section>
        <Destination type={"indian"} />
        <section className="section_heading ">
          <div className="container-fluid">
            <div className="mobile-font">
              <h4>Best Deals of Seasons</h4>
            </div>
            <p style={{ fontSize: '17px' }}>
              {parse(readingbestdealsText)}{' '}
              <Link to="#" onClick={toggleText}>
                {expanded ? 'Read Less...' : 'Read More…'}
              </Link>
            </p>
          </div>
        </section>
        <Destination type={"shortbrake"} />
        <Features alignment={"text-center"} />
        <OurClient alignment={"text-center"} />
        <OurAcclamations alignment={"text-center"} />
        <section className="section_heading ">
          <div className="row">
            <div className="container-fluid heading">
              <h4 className="text-center" style={{ padding: "0px 10px", fontWeight: "bold" }}>Latest Blogs</h4>
            </div>
          </div>
        </section>
        <LatestBlog />
        <Address />
        <Footer />
      </div>
    </>
  );
};

export default Home;
