import React from "react";
import "./style.css";
import Slider from "react-slick";
import HotelCard from "../../HotelCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Destination = (props) => {
  const data = useSelector((state) => state?.tableHeader);
  const requiredDestination = props.type + "Destinations";
  const destinations = data?.data?.[requiredDestination] || [];
  console.log(destinations)

  var settings = {
    slidesToShow: 5,
    dots: false,
    infinite: true,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.9,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2.3,
        },
      },
    ],
  };

  return (
    <section className="section-destination">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              {destinations.map((internationalDestinations, index) => (
                <Link key={index} to={'/package/' + internationalDestinations?.destinations?.destination_slug+'/' + internationalDestinations?.destinations?.destination_id}
                  state={{
                    // 'id': internationalDestinations?.destinations?.destination_id,
                    "name": internationalDestinations?.destinations?.destination_name, 
                    "bannerImage": internationalDestinations?.destinations?.destination_banner_image, 
                    "destinationSlug": internationalDestinations?.destinations?.destination_slug,
                    "cardImage": internationalDestinations?.destinations?.destination_card_image, 
                  }}>
                  <div key={index}>
                    <HotelCard
                      key={internationalDestinations[props.type + "_destination_id"]}
                      destinationName={internationalDestinations[props.type + "_destination_name"]}
                      imageUrl={internationalDestinations[props.type + "_destination_image"]}
                      price={internationalDestinations[props.type + "_price"]}
                    />
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Destination;
