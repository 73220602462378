import { Link } from "react-router-dom";
import "./style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { WhatsappShareButton } from "react-share";

const Itinerary = (props) => {
    const webURL = window.location.href;
    return (
        <div className="mx-3 my-3">
            <div className="itinerary_icon d-flex justify-content-evenly">
                <div>
                    <WhatsappShareButton url={webURL} style={{ margin: "0px 5px" }}>
                        <i class="bi bi-whatsapp"></i>
                    </WhatsappShareButton><br /><p>whatsapp<br />itinerary</p>
                </div>
                <div>
                    <Link to={`https://mail.google.com/mail/u/0/?fs=1&tf=cm&body=${webURL}`} target="_blank" className="d-flex flex-column"><i class="bi bi-envelope"></i><p>email<br />itinerary</p></Link>
                </div>
                <div>
                    <Link to={"/pdf/" + props.pdfId}
                        state={{
                            "data": props?.data,
                            "incData": props?.incData,
                            "excData": props?.excData,
                            "hotelDetails": props?.hotelDetails,
                            "disclaimer": props?.disclaimer,
                            "title": props?.title,
                            "overview": props?.overview
                        }} className="d-flex flex-column"
                    ><i class="bi bi-printer"></i><p>print<br />itinerary</p></Link>
                </div>
            </div>
        </div>
    );
};
export default Itinerary;
