import "./style.css";
import ContactForm from "../ContactForm";
import { Link } from "react-router-dom";
const DestinationPrice = (props) => {
  const formId = "form"+props.tourId;
  return (
    <div className="destination_price" style={{height : "100%"}}>
      <p>{props.duration}</p>
      <p>{props.tourType}</p>
      <div className="inner">
        <h3>₹{props.price}</h3>
        <span>Per Person</span>
        <div className="d-flex flex-wrap justify-content-around">

          <button type="button"
              className="btn btn-primary w-75 mx-1 mt-1"><Link style={{ textDecoration: "none", color :"white"}} to={"/package/"+props.destinationName+"/"+props.tourId+"/"+props.description} state={{ "tourId": props.tourId }}>KNOW MORE</Link></button>
        
        <button
          type="button"
          className="btn btn-primary w-75 mx-1 mt-1"
          data-bs-toggle="modal"
          data-bs-target={"#"+formId} //through props
        >
          CONTACT NOW
        </button>
      </div>
      <div
        className="modal fade"
        id={formId} //through props
        tabIndex={-1}
        aria-labelledby={formId+"Label"}
        aria-hidden="true"
      >
        <div className="modal-dialog d-flex jusitfy-content-center align-items-center h-100">
          <div className="modal-content">
            <div className="modal-body">
              <ContactForm description={props.description} popUpImage={props.popUpImage}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div >
  );
};
export default DestinationPrice;
