
import API from './API';
import RouteURL from './RouteUrl';

const Contact = {
    newContact: function (name, mob_number, email, description) {
        return new Promise((resolve, reject) => {
            API.post(`${RouteURL.postContact}`, {
                "full_name": name,
                "mobile_number": mob_number,
                "email": email,
                "description": description
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            },)
                .then(res => {
                    resolve(res.data);
                    console.log(res.data)
                })
                .catch(error => {
                    reject(error);
                })
        })
    }
}

export default Contact;