
import DestinationPrice from "../DestinationPrice";
import PackageDeal from "../PackageDeal";
import "./style.css";

const DestinatonCard = (props) => {
  return (
    <div className="destination_card">
      <div className="container-fluid">
        <div className="destination_card-inner">
          <div className="row">
            <div className="col-12 col-xl-3 destinationimg">
              <img src={`${props.image}`} alt="" className="w-100" />
            </div>
            <div className="col-12 col-xl-6">
              <div className="package">
                <h2>{props.name}</h2>
                <span>
                  {props.destinationCover}
                </span>
                <PackageDeal selectDeal={props.selectDeal} />
              </div>
            </div>

            <div className="col-12 col-xl-3">
              <DestinationPrice destinationName={props.destinationName} duration={props.duration} price={props.price} description={props.tourUrl} tourId={props.tourId} tourType={props.tourType} popUpImage={props.popUpImage}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DestinatonCard;
