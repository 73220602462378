import Address from "../../Components/Address";
import SideBarContactForm from "../../Components/SideBarContactForm"
import DestinationPrice from "../../Components/DestinationPrice";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import PackageDeal from "../../Components/PackageDeal";
import RecentPost from "../../Components/RecentPost";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import Itinerary from "../../Components/Itinerary";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailedPackageApiService from "../../services/DetailedPackage";
import { useEffect, useState } from "react";
import { PACKAGEDETAIL, TABLEHEADER } from "../../redux/types";
import Loader from "../LoaderSpinner";
import parse from 'html-react-parser';
import PageNotFound from "../PageNotFound";

const DetailedPackage = () => {
  window.scroll(0, 0);
  let data = useSelector((state) => state?.packageDetail);
  const blogs = data?.data?.latest_blog || [];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const tourId = useParams().tourId;
  const destinationName = useParams().pageurl;
  const specificDestinationName = useParams().pagename;
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const offset = sectionElement.offsetTop - 220;
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      DetailedPackageApiService.getDetailedPackage(tourId)
        .then((res) => {
          dispatch({
            type: PACKAGEDETAIL,
            payload: res,
          });
          console.log(res);
          if(res.data.tours_url !== specificDestinationName) navigate("/pagenotfound");
          console.log(details.tours_url)
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          navigate("/pagenotfound");
        });
    }, 3000)
  }, [tourId]);

  const details = data?.data || [];
  const ads = data?.ads || [];
  const pageTitle = "ALC Travels - " + details.tours_name;

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SocialSideBar />
      <Header />
      {loading ? <div><Loader /></div> : <> <div className="page-banner">
        <img src={"https://admin.alctravels.com/storage/" + details.tours_banner} className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>{details.tours_name}</h1>
        </div>
      </div>
        <div className="detailed_package-main">
          <div className="container-fluid">
            <div className="row detailed_package">
              <div className="col-12 col-xl-8">
                <div className="package_benfits">
                  <PackageDeal selectDeal={details.select_inclusion} />
                </div>
                <div className="heading">
                  <h2 style={{ cursor: "pointer" , borderRight : "2px solid white"}} onClick={() => scrollToSection('overview')}>Overview</h2>
                  <h2 style={{ cursor: "pointer" , borderRight : "2px solid white"}} onClick={() => scrollToSection('itinerary')}>Itinerary</h2>
                  <h2 style={{ cursor: "pointer" , borderRight : "2px solid white"}} onClick={() => scrollToSection('inclusions')}>Inclusions</h2>
                  <h2 style={{ cursor: "pointer" , borderRight : "2px solid white"}} onClick={() => scrollToSection('exclusions')}>Exclusions</h2>
                  <h2 style={{ cursor: "pointer" , borderRight : "2px solid white"}} onClick={() => scrollToSection('Hotels')}>Hotels</h2>
                  <h2 style={{ cursor: "pointer" }} onClick={() => scrollToSection('disclaimer')}>Disclaimer</h2>
                </div>
                <p id="overview">
                  {parse(details.tours_overview + "")}
                </p>
                <div className="heading">
                  <h2 id="itinerary">itinerary</h2>
                </div>
                <div className="itinerary-inner">
                  {details.tour_itineries.map((elem) => {
                    return (
                      < div key={elem.tours_itineries_id} >
                        <div className="info">
                          <img src="../../../package-loc.png" alt="" />
                          <h3 style={{fontWeight:"bold"}}>{elem.tours_itineries_title}</h3>
                        </div>
                        <div>
                          {parse(elem.tours_itineries_desc)}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="heading">
                  <h2 id="inclusions">inclusions</h2>
                </div>
                <p id="inclusionsPara">
                  {parse(details.tours_inclusion + "")}
                </p>
                <div className="heading">
                  <h2 id="exclusions">exclusions</h2>
                </div>
                <p id="exclusionPara">
                  {parse(details.tours_exclusion + "")}
                </p>
                <div className="heading">
                  <h2 id="Hotels">Hotels</h2>
                </div>
                {details.hotels.map((ele) => {
                  return (
                    <div className="hotel-inner mb-4" key={ele.id}>
                      <img src={"https://admin.alctravels.com/storage/" + ele.hotel_banner} className="w-100" alt="" />
                      <div className="info">
                        <p>
                          <strong>Hotel Name</strong> :- {ele.hotel_name}
                        </p>
                        <p>
                          <strong>Location</strong> :- {ele.hotel_location}
                        </p>
                        <div>
                          {
                            Array(ele.hotel_rate).fill(null).map((element) => {
                              return <img src="../../../star.png" alt="" />
                            })
                          }

                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className="heading">
                  <h2 id="disclaimer">disclaimer</h2>
                </div>
                <p id="disclaimerPara" className="mb-4">
                  {parse(details.tours_disclaimer + "")}
                  
                </p>
                <div className="heading">
                  <h2>Gallery</h2>
                </div>
                <div className="hotel-inner gallery-inner mb-4">
                  {details.tours_gallery.map((ele) => {
                    return (
                      <img src={"https://admin.alctravels.com/storage/" + ele} className="w-100" alt="" />
                    )
                  })}
                </div>
              </div>
              <div className="col-12 col-xl-4">
                <div className="package-price">
                  <DestinationPrice destinationName={destinationName} description={specificDestinationName} duration={details.tours_duration} price={details.tours_price} tourId={tourId} tourType={details.tour_type} popUpImage={ads.POPUPAD} />

                  <Itinerary pdfId={details.tours_id} pdf={details.tours_itinerary_pdf} data={details.tour_itineries} incData={details.tours_inclusion} excData={details.tours_exclusion} hotelDetails={details.hotels} disclaimer={details.tours_disclaimer} overview={details.tours_overview} title={details.tours_name}/>
                  
                  <SideBarContactForm />
                  <img src={"https://admin.alctravels.com/storage/" + ads?.SIDEBAR?.ads_image} alt="" className="w-100 my-4" />
                  <h2>Latest Blog</h2>
                  {blogs.slice(0, 10).map((ele) => (
                    <RecentPost id={ele.id} pageUrl={ele.page_url} featured_image={ele.featured_image}  title={ele.title} updated_at={ele.updated_at} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }

      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div >
  );
};

export default DetailedPackage;
