import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import parse from 'html-react-parser';
import font from './futura.ttf';
import starImage from './star.png';
import alcLogo from './ALC Logo 7.png';

Font.register({
    family: 'futura',
    src: font
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 30,
        fontFamily: "futura",
    },
    section: {
        flexGrow: 1,
    },
    title: {
        marginBottom: 20,
        marginTop: 20,
        backgroundColor: "#0d6efd",
        fontSize: "15px",
        color: "white",
        paddingTop: 5,
        paddingBottom: 5,
        paddingleft: 5,
    },
    data: {
        marginBottom: 5,
        marginTop: 5,
        fontSize: "12px",
    },
    mainheading: {
        marginBottom: 10,
        marginTop: 10,
        fontSize: "25px",
        color: "#0d6efd",
    },
    hotelInnerImg: {
        width: "20px",
        borderRadius: 5,
        border: 0
    },
    hotelsInner: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #0d6efd",
        marginBottom: "5px",
        paddingBottom: "10px"
    }
});

const CreatePdf = (props) => {
    const data = props.data || [];
    const incData = props.incdata || [];
    const excData = props.excdata || [];
    const hotelDetails = props.hotelDetails || [];
    const disclaimer = props.disclaimer || [];
    const title = props.title || [];
    const overview = props.overview || [];

    const getData = (elem) => {
        if (typeof elem === "string") {
            return [elem];
        } else if (Array.isArray(elem)) {
            return elem.flatMap(child => getData(child));
        } else if (elem?.props?.children) {
            return getData(elem.props.children).flat();
        } else {
            return [];
        }
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image style={{ width: "20%" }} src={alcLogo}></Image>
                    <Text style={styles.mainheading}>{title}</Text>
                    {
                        <React.Fragment>
                            <Text style={styles.data}>{parse(overview + "")?.props?.children}</Text>
                        </React.Fragment>
                    }
                    <Text style={styles.mainheading}>Itinerary</Text>
                    {data.map((ele, index) => (
                        <React.Fragment key={index}>
                            <Text style={{...styles.title , marginTop : index === 0 ? 0 : 20}}> {ele.tours_itineries_title}</Text>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                                {
                                    getData(parse(ele.tours_itineries_desc + "")).map((child, index) => (
                                        <View style={{display: "flex" , flexDirection: "row"}}>
                                            <Text style={styles.data}>•</Text>
                                            <Text key={index}  style={styles.data}>{child}</Text>
                                        </View>
                                    ))
                                }
                            </div>
                        </React.Fragment>
                    ))}


                    <React.Fragment>
                        <Text style={styles.mainheading}>Inclusion</Text>
                        {
                            getData(parse(incData + "")).map((child, index) => (
                                child.length > 1 && <Text key={index} style={styles.data}>•{child}</Text>
                            ))
                        }
                    </React.Fragment>

                    <React.Fragment>
                        <Text style={styles.mainheading}>Exclusion</Text>
                        {
                            getData(parse(excData + "")).map((child, index) => (
                                child.length > 1 && <Text key={index} style={styles.data}>•{child}</Text>
                            ))
                        }
                    </React.Fragment>

                    <React.Fragment>
                        <Text style={styles.mainheading}>Hotels</Text>
                        {hotelDetails.map((ele, index) => (
                            <div key={index} style={{...styles.hotelsInner}} className='w-100'>
                                <div>
                                    <p><Text style={styles.data}>Hotel Name : {ele.hotel_name}</Text></p>
                                    <p><Text style={styles.data}>Location : {ele.hotel_location}</Text></p>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {
                                            Array(ele.hotel_rate).fill(null).map((element, index) => {
                                                return <Image key={index} style={styles.hotelInnerImg} src={starImage} alt="" />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </React.Fragment>

                    <React.Fragment>
                        <Text style={styles.mainheading}>Disclaimer</Text>
                        {
                            getData(parse(disclaimer + "")).map((child, index) => (
                                child.length > 1 && <Text key={index} style={styles.data}>•{child}</Text>
                            ))
                        }
                    </React.Fragment>

                    <Text style={styles.mainheading}>Thank You</Text>

                </View>
            </Page>
        </Document>
    );
};

export default CreatePdf;