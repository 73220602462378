import Slider from "react-slick";
import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import OurClient from "../../Components/Home/OurClient";
import "./style.css";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Mice = () => {
  let data = useSelector((state) => state?.tableHeader);
  const ourClient = data?.data?.ourClient || [];
  const gallery_img = [
    "./GalleryImages/img2.jpg",
    "./GalleryImages/img1.jpg",
    "./GalleryImages/img3.jpg",
    "./GalleryImages/img4.jpg",
    "./GalleryImages/img5.jpg",
    "./GalleryImages/img6.jpg",
    "./GalleryImages/img7.jpg",
    "./GalleryImages/img8.jpg",
    "./GalleryImages/img9.jpg",
    "./GalleryImages/img10.jpg",
    "./GalleryImages/img11.jpg",
  ]
  const gallery_vid = [
    "./GalleryImages/vid1.mp4",
    "./GalleryImages/vid2.mp4",
    "./GalleryImages/vid3.mp4",
    "./GalleryImages/vid4.mp4",
    "./GalleryImages/vid5.mp4",
    "./GalleryImages/vid6.mp4",
    "./GalleryImages/vid7.mp4",
  ]

  var settings = {
    slidesToShow: 3,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settings2 = {
    slidesToShow: 3,
    dots: false,
    infinite: true,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const pageTitle = "ALC Travels - Mice";

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SocialSideBar />
      <Header />
      <div className="page-banner">
        <img src="./mice-banner.svg" className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>MICE</h1>
        </div>
      </div>

      <div className="section_mice-bannerbottom">

        <div className="heading justify-content-around">
          <div>
            <h2>MEETING</h2>
          </div>
          <div>
            <h2>Incentive</h2>
          </div>
          <div>
            <h2>conference</h2>
          </div>
          <div>
            <h2>Events</h2>
          </div>
          <div>
            <h2>exhibitions</h2>
          </div>
        </div>

      </div>
      <div className="section_mice-experienced">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2 className="text-center">Experienced Mice Company</h2>
                <p>
                  MICE is an acronym of Meetings, Incentives, Conferences and Exhibitions. We focus on providing end-to-end MICE solutions. Meetings, Incentives, Conferences and Events executed by ALC team are a result of extensive planning and in-depth research to ensure the most comprehensive MICE experience. We are experienced and equipped to handle all MICE events on a turnkey basis, right from the commencing stages of a project until completion of the project.
                </p>
                <p>
                  We at ALC cater several MICE-related requirements in within India and across the globe. Our team of well-experienced MICE planners would be able to assist you with your intricate MICE requirements from end-to-end planning, and supervision followed by flawless execution. We have successfully handled many destinations worldwide for group travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_mice-service">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="heading">
                <div>
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/MEETING&Incentives.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>MEETING & INCENTIVES</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/CONFRENCES&EVENTS.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>CONFRENCES & EVENTS</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/DESTINATIONWEDDING.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>DESTINATION WEDDINGS</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/DESTINATIONMANAGEMENT.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>DESTINATION MANAGEMENT</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/FLIGHTS&HOTELS.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>FLIGHTS & HOTELS</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/VISACONSULTANCY.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>VISA CONSULTANCY</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/TRAVELINSURANCE.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>TRAVEL INSURANCE</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 mx-3 mb-4">
              <div className="service-banner">
                <img src="./OurServicesImage/TRANSPORTSERVICES.jpg" className="w-100" alt=""></img>
                <div className="servicebanner_heading">
                  <h2>TRANSPORT SERVICES</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_mice-gallery">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <div>
                  <h2>Our Gallery</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-6 mt-4">
              <div className="inner">
                <div className="row img-r">
                  <Slider {...settings}>
                    {gallery_img.map((ele) => (
                      <div className="col-6 img-c">
                        <img src={ele} className="w-100 p-1" alt="" />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="row img-r">
                  <Slider {...settings2}>
                    {gallery_vid.map((ele) => (
                      <div className="col-6">
                        <video src={ele} controls className="w-100 p-1"></video>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section_client">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="row">
              <div className="col-lg-12 heading">
                <h2 style={{ fontWeight: "bold" }} className={"text-center"}>Our Clients</h2>
              </div>
            </div>
            {ourClient.map((banner) => (
              <div className="col-lg-2 col-md-4 col-4 our-client" key={banner.id}>
                <div className="rounded-3 client-inner">
                  <img src={`https://admin.alctravels.com/storage/${banner.our_client_image}`} alt="" className="w-75" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default Mice;
