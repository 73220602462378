import React from 'react'
import Header from '../../Components/Header'
import Address from '../../Components/Address'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <>
    <Header/>
    <div className="container">
        <div style={{textAlign : "center" , margin : "10%"}}>
            <img className='w-100' src="../404.png" alt="" />
            <Link to={"/"}><div className="btn btn-primary p-3 mt-5 w-75">GO TO HOMEPAGE</div></Link>
        </div>
        
    </div>
    <Address/>
    <Footer/>
    </>
  )
}

export default PageNotFound
