import { Link } from "react-router-dom";
import "./style.css";
import dateFormat from 'dateformat';

const RecentPost = (props) => {
  return (
    <div className="recent">
      <div className="recent">
        <div className="thumb">
          <img
            src={"https://admin.alctravels.com/storage/"+props.featured_image}
            className="w-100"
            alt=""
          />
          <div className="">
            <Link style={{textDecoration:"none"}} to={"/blog/"+props.id+"/"+props.pageUrl}><h3>{props.title}</h3></Link>
            <p>{dateFormat(props.updated_at, "mmmm dS, yyyy")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecentPost;
